import App from './App.svelte';
import { app as firebaseApp, analytics } from './firebase';

// Verify analytics initialization
if (analytics) {
	console.log('Analytics is available in main.js');
} else {
	console.warn('Analytics is not available - might be blocked by browser settings or extensions');
}

const app = new App({
	target: document.body,
	props: {
		url: ''
	}
});

export default app;