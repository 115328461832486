<script>
  import { onMount } from 'svelte';
  import { showNavigation } from './stores/navigation';

  onMount(() => {
    showNavigation.set(false);
    return () => {
      showNavigation.set(true);
    };
  });

  const features = [
    {
      title: "Discover Christian Concerts",
      description: "Find uplifting worship events and concerts near you",
      icon: "🎵"
    },
    {
      title: "Connect with Faith Community",
      description: "Join thousands of believers celebrating through music",
      icon: "🤝"
    },
    {
      title: "Smart Concert Filtering",
      description: "Filter events by location, date, and more",
      icon: "🔍"
    }
  ];
</script>

<div class="app-promotion-container">
  <div class="app-promotion-hero">
    <h1 class="hero-title">Your Gateway to Christian Concerts & Worship Events</h1>
    <p class="hero-subtitle">Join thousands of believers discovering faith-filled musical experiences</p>
    
    <div class="app-promotion-buttons">
      <a href="https://apps.apple.com/us/app/heavenya-christian-fellowship/id1498340882" target="_blank" rel="noopener noreferrer" class="app-store-link">
        <img src="/assets/apple_store.png" alt="Download on App Store" class="app-store-image" />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.faithful.heavenya" target="_blank" rel="noopener noreferrer" class="app-store-link">
        <img src="/assets/google_store.png" alt="Get it on Google Play" class="app-store-image" />
      </a>
    </div>

    <div class="features-grid">
      {#each features as feature}
        <div class="feature-card">
          <span class="feature-icon">{feature.icon}</span>
          <h3 class="feature-title">{feature.title}</h3>
          <p class="feature-description">{feature.description}</p>
        </div>
      {/each}
    </div>

    <div class="testimonial-section">
      <div class="testimonial-content">
        <h2 class="section-title">Why Choose Heavenya?</h2>
        <p class="testimonial-text">
          "Experience the joy of worship through live Christian concerts and events. From contemporary Christian music to gospel celebrations, find the perfect event that speaks to your soul."
        </p>
        <ul class="benefits-list">
          <li>✓ Easily discover Christian concerts in your area</li>
          <li>✓ Connect with other believers who share your passion for worship</li>
          <li>✓ Filter events based on date and location</li>
          <li>✓ Share your favorite Christian music events</li>
        </ul>
      </div>
    </div>

    <div class="app-showcase">
      <div class="app-screenshots">
        <img src="/assets/event_videos.png" alt="Event Videos" class="app-screenshot" />
        <img src="/assets/event_details.png" alt="Event Details" class="app-screenshot" />
        <img src="/assets/save_events.png" alt="Save Events" class="app-screenshot" />
        <img src="/assets/search_events.png" alt="Search Events" class="app-screenshot" />
      </div>
    </div>

    <div class="cta-section">
      <h2 class="cta-title">Start Your Faith Journey Today</h2>
      <p class="cta-description">Join our growing community of believers and experience worship in a whole new way.</p>
      <div class="download-buttons">
        <a href="https://apps.apple.com/us/app/heavenya-christian-fellowship/id1498340882" target="_blank" rel="noopener noreferrer" class="app-store-link">
          <img src="/assets/apple_store.png" alt="Download on App Store" class="app-store-image" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.faithful.heavenya" target="_blank" rel="noopener noreferrer" class="app-store-link">
          <img src="/assets/google_store.png" alt="Get it on Google Play" class="app-store-image" />
        </a>
      </div>
    </div>
  </div>
</div>
