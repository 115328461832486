<script>
    import { onMount } from 'svelte';
    import { collection, addDoc, serverTimestamp, updateDoc, doc } from 'firebase/firestore';
    import { storage } from './firebase.js'; // Add this import
    import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Add these imports
    import { db } from './firebase.js';
    
    let authenticated = false;
    let email = '';
    let password = '';
    
    // Form data
    let eventData = {
        eventName: '',
        eventType: 'In Person',
        eventDate: '',
        details: '',
        locationAddress: '',
        learnMoreUrl: '',
        url: '',
        price: 'Paid',
        minimumPrice: 0,
        maximumPrice: 0,
        shareText: '',
        userEmail: 'promoter@heavenya.com', // Set default email
        contentType: 'YouTube Video', // Add default content type
        imageUrl: '' // Add for image uploads
    };

    // Error handling
    let error = '';
    let success = '';

    // Add upload status tracking
    let isUploading = false;
    let uploadProgress = 0;

    // Validate YouTube URL
    function isValidYouTubeUrl(url) {
        if (!url) return false;
        const ytPattern = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/shorts\/)([a-zA-Z0-9_-]{11})$/;
        return ytPattern.test(url);
    }

    function authenticate() {
        if (email === 'promoter@heavenya.com' && password === '123456') {
            authenticated = true;
            error = '';
            // Set the userEmail when authenticated
            eventData.userEmail = email;
        } else {
            error = 'Invalid credentials';
        }
    }

    // Helper function to sanitize and format address
    function formatAddress(address) {
        return address
            .trim()
            .replace(/\s+/g, ' ')  // Replace multiple spaces with single space
            .replace(/,\s*/g, ','); // Remove spaces after commas
    }

    // Update the geocoding logic
    async function getCoordinates(address) {
        try {
            // Better address cleaning
            const cleanAddress = address
                .replace(/\s+/g, ' ') // Replace multiple spaces with single space
                .replace(/#/g, '') // Remove # symbols
                .replace(/[^\w\s,-]/g, '') // Remove special characters except commas and hyphens
                .trim();
                
            const encodedAddress = encodeURIComponent(cleanAddress);
            const response = await fetch(`/api/geocode?address=${encodedAddress}`);
            
            let data;
            try {
                const text = await response.text();
                data = JSON.parse(text);
            } catch (parseError) {
                console.error('Response parsing error:', parseError);
                throw new Error('Invalid response from geocoding service');
            }

            if (data.error) {
                throw new Error(data.error);
            }

            if (!data.results || !Array.isArray(data.results) || data.results.length === 0) {
                throw new Error('No results found for this address');
            }

            const result = data.results[0];
            const location = result.geometry?.location;

            if (!location || typeof location.lat !== 'number' || typeof location.lng !== 'number') {
                throw new Error('Invalid location data received');
            }

            return {
                latitude: location.lat,
                longitude: location.lng,
                locationType: result.geometry.location_type,
                formattedAddress: result.formatted_address
            };
        } catch (e) {
            console.error('Geocoding error:', {
                error: e,
                address: address
            });
            throw new Error('Unable to find coordinates for this address. Please try simplifying it.');
        }
    }

    async function handleSubmit() {
        try {
            // Validate YouTube URL if content type is YouTube Video
            if (eventData.contentType === 'YouTube Video' && !isValidYouTubeUrl(eventData.url)) {
                error = 'Please enter a valid YouTube or YouTube Shorts URL';
                return;
            }

            const timestamp = serverTimestamp();
            
            // Convert selected date to both UTC and local time
            const selectedDate = new Date(eventData.eventDate);
            const utcDate = selectedDate.toISOString();
            const localDate = new Date(selectedDate.getTime() - (selectedDate.getTimezoneOffset() * 60000)).toISOString();

            // Match the Flutter app's data structure exactly
            const submitData = {
                details: eventData.details,
                eventDate: new Date(utcDate), // Store as Firestore timestamp in UTC
                eventDateLocal: localDate, // Store local time as ISO string
                eventDateUTC: utcDate, // Also store UTC as ISO string for consistency
                eventName: eventData.eventName,
                learnMoreUrl: eventData.learnMoreUrl,
                locationAddress: eventData.locationAddress || '', // Keep original address
                locationAddressLatitude: null,  // Initialize as null
                locationAddressLongitude: null, // Initialize as null
                shareText: eventData.shareText,
                eventType: eventData.eventType,
                url: eventData.url,
                userEmail: eventData.userEmail,
                promoterId: "Rg8aoXajmfc9ncFvxbBA5lqido92",
                createdAt: timestamp,
                price: eventData.price,
                minimumPrice: eventData.price === "Paid" ? 
                    (Number.isFinite(eventData.minimumPrice) ? Number(eventData.minimumPrice) : 0) : 0,
                maximumPrice: eventData.price === "Paid" ? 
                    (Number.isFinite(eventData.maximumPrice) ? Number(eventData.maximumPrice) : 0) : 0,
                documentId: '',
                contentType: eventData.contentType // Use the selected content type directly
            };

            // Add coordinates for in-person events but don't block submission on failure
            if (eventData.eventType === "In Person" && eventData.locationAddress) {
                try {
                    const geoResult = await getCoordinates(eventData.locationAddress);
                    
                    submitData.locationAddressLatitude = geoResult.latitude;
                    submitData.locationAddressLongitude = geoResult.longitude;
                    submitData.locationAddress = geoResult.formattedAddress;
                    
                    console.log("Geocoding successful:", {
                        address: geoResult.formattedAddress,
                        coordinates: {
                            lat: geoResult.latitude,
                            lng: geoResult.longitude
                        }
                    });
                } catch (e) {
                    console.warn("Geocoding failed, using original address:", {
                        address: eventData.locationAddress,
                        error: e.message
                    });
                    // Keep original address and set coordinates to null
                    submitData.locationAddress = eventData.locationAddress;
                    submitData.locationAddressLatitude = null;
                    submitData.locationAddressLongitude = null;
                    // Show warning but don't block submission
                    success = 'Event will be submitted with unformatted address';
                }
            }

            // Continue with submission regardless of geocoding result
            const docRef = await addDoc(collection(db, 'events'), submitData);
            console.log('Document written with ID: ', docRef.id);
            
            // Update documentId after creation
            await updateDoc(doc(db, 'events', docRef.id), {
                documentId: docRef.id
            });

            success = 'Event submitted successfully!';
            // Reset form
            eventData = {
                eventName: '',
                eventType: 'In Person',
                eventDate: '',
                details: '',
                locationAddress: '',
                learnMoreUrl: '',
                url: '',
                price: 'Paid',
                minimumPrice: 0,
                maximumPrice: 0,
                shareText: '',
                userEmail: 'promoter@heavenya.com', // Set default email
                contentType: 'YouTube Video', // Add default content type
                imageUrl: '' // Add for image uploads
            };
        } catch (e) {
            console.error('Submission error details:', e);
            error = `Error submitting event: ${e.message}`;
        }
    }

    // Handle image upload
    async function handleImageUpload(event) {
        const file = event.target.files[0];
        if (!file) return;

        try {
            isUploading = true;
            error = '';

            // Create a reference to the event_images folder
            const storageRef = ref(storage, `event_images/${Date.now()}_${file.name}`);

            // Upload the file
            await uploadBytes(storageRef, file);

            // Get the download URL
            const downloadUrl = await getDownloadURL(storageRef);

            // Update the eventData with the image URL
            eventData.url = downloadUrl;
            
            success = 'Image uploaded successfully!';
        } catch (e) {
            console.error('Upload error:', e);
            error = `Error uploading image: ${e.message}`;
        } finally {
            isUploading = false;
        }
    }
</script>

{#if !authenticated}
    <div class="auth-container">
        <div class="auth-card">
            <div class="auth-header">
                <img src="/assets/heavenya.png" alt="Heavenya Logo" class="auth-logo">
                <h2>Welcome Back</h2>
                <p class="auth-subtitle">Sign in to post new events</p>
            </div>
            
            {#if error}
                <div class="error-alert">
                    {error}
                </div>
            {/if}
            
            <form class="auth-form" on:submit|preventDefault={authenticate}>
                <div class="form-group">
                    <input 
                        bind:value={email}
                        type="email" 
                        required 
                        class="form-input"
                        placeholder="Email"
                    />
                </div>
                <div class="form-group">
                    <input 
                        bind:value={password}
                        type="password" 
                        required 
                        class="form-input"
                        placeholder="Password"
                    />
                </div>
                <button type="submit" class="submit-btn">
                    Sign in
                </button>
            </form>
        </div>
    </div>
{:else}
    <div class="post-container compact">
        <div class="post-header">
            <h1>Post New Event</h1>
            <p class="post-subtitle">Share your event with the community</p>
        </div>
        
        {#if error}
            <div class="error-alert">
                {error}
            </div>
        {/if}
        
        {#if success}
            <div class="success-alert">
                {success}
            </div>
        {/if}

        <form on:submit|preventDefault={handleSubmit} class="post-form">
            <div class="form-grid compact">
                <!-- Event Details Column -->
                <div class="form-section top-row">
                    <h3 class="section-title">Event Details</h3>
                    <div class="input-group">
                        <label for="eventName">Event Name</label>
                        <input 
                            id="eventName"
                            bind:value={eventData.eventName}
                            type="text" 
                            required
                            class="form-input"
                        />
                    </div>

                    <div class="input-group">
                        <label for="eventType">Event Type</label>
                        <select 
                            id="eventType"
                            bind:value={eventData.eventType}
                            class="form-select"
                        >
                            <option>In Person</option>
                            <option>Virtual</option>
                        </select>
                    </div>

                    <div class="input-group">
                        <label for="eventDate">Event Date</label>
                        <input 
                            id="eventDate"
                            bind:value={eventData.eventDate}
                            type="datetime-local" 
                            required
                            class="form-input"
                        />
                    </div>
                </div>

                <!-- Location & Media Column -->
                <div class="form-section top-row">
                    <h3 class="section-title">Location & Media</h3>
                    {#if eventData.eventType === 'In Person'}
                        <div class="input-group">
                            <label for="locationAddress">Location Address</label>
                            <input 
                                id="locationAddress"
                                bind:value={eventData.locationAddress}
                                type="text" 
                                required
                                class="form-input"
                            />
                        </div>
                    {/if}

                    <div class="input-group">
                        <label for="contentType">Content Type</label>
                        <select 
                            id="contentType"
                            bind:value={eventData.contentType}
                            class="form-select"
                        >
                            <option value="YouTube Video">YouTube Video</option>
                            <option value="Image Upload">Image Upload</option>
                        </select>
                    </div>

                    {#if eventData.contentType === 'YouTube Video'}
                        <div class="input-group">
                            <label for="youtubeUrl">YouTube Video URL</label>
                            <input 
                                id="youtubeUrl"
                                bind:value={eventData.url}
                                type="url" 
                                required
                                class="form-input"
                                placeholder="Enter YouTube video URL"
                            />
                        </div>
                    {:else}
                        <div class="input-group">
                            <label for="imageUpload">Upload Image</label>
                            <input 
                                id="imageUpload"
                                type="file" 
                                accept="image/*"
                                on:change={handleImageUpload}
                                class="form-input"
                                disabled={isUploading}
                            />
                        </div>
                    {/if}
                </div>

                <!-- Pricing & Links Column -->
                <div class="form-section top-row">
                    <h3 class="section-title">Pricing & Links</h3>
                    <div class="input-group">
                        <label for="priceType">Price Type</label>
                        <select 
                            id="priceType"
                            bind:value={eventData.price}
                            class="form-select"
                        >
                            <option>Paid</option>
                            <option>Free</option>
                        </select>
                    </div>

                    {#if eventData.price === 'Paid'}
                        <div class="price-inputs">
                            <div class="input-group">
                                <label for="minPrice">Minimum Price</label>
                                <input 
                                    id="minPrice"
                                    bind:value={eventData.minimumPrice}
                                    type="number" 
                                    min="0"
                                    required
                                    class="form-input"
                                />
                            </div>

                            <div class="input-group">
                                <label for="maxPrice">Maximum Price</label>
                                <input 
                                    id="maxPrice"
                                    bind:value={eventData.maximumPrice}
                                    type="number" 
                                    min="0"
                                    required
                                    class="form-input"
                                />
                            </div>
                        </div>
                    {/if}

                    <div class="input-group">
                        <label for="learnMoreUrl">Learn More URL</label>
                        <input 
                            id="learnMoreUrl"
                            bind:value={eventData.learnMoreUrl}
                            type="url" 
                            required
                            class="form-input"
                            placeholder="Ticket registration link"
                        />
                    </div>
                </div>

                <!-- Full width section for details -->
                <div class="form-section full-width">
                    <div class="input-group">
                        <label for="shareText">Share Text</label>
                        <input 
                            id="shareText"
                            bind:value={eventData.shareText}
                            type="text" 
                            required
                            class="form-input"
                        />
                    </div>

                    <div class="input-group">
                        <label for="eventDetails">Event Details</label>
                        <textarea 
                            id="eventDetails"
                            bind:value={eventData.details}
                            rows="4"
                            required
                            class="form-textarea"
                        ></textarea>
                    </div>
                </div>
            </div>

            <div class="form-actions">
                <button type="submit" class="submit-btn">
                    Submit Event
                </button>
            </div>
        </form>
    </div>{/if}