<!-- App.Svelte -->
<script>
    import { Router, Route, Link, navigate } from 'svelte-routing';
    import { onMount } from 'svelte';
    import Main from './Main.svelte';
    import EventDetails from './EventDetails.svelte';
    import Post from './Post.svelte';
    import AppPromotion from './AppPromotion.svelte';
    import { showNavigation } from './stores/navigation';

    export let url = '';

    // Helper function to extract query parameters
    function getQueryParams(search) {
        const params = new URLSearchParams(search);
        return Object.fromEntries(params.entries());
    }

    let queryId;

    // Theme management
    function isDarkMode() {
        const today = new Date();
        const start = new Date(today.getFullYear(), 0, 0);
        const diff = today - start;
        const dayOfYear = Math.floor(diff / (1000 * 60 * 60 * 24));
        return dayOfYear % 2 === 1;
    }

    function updateTheme() {
        const theme = isDarkMode() ? 'dark' : 'light';
        document.documentElement.setAttribute('data-theme', theme);
    }

    // Extract query params and initialize theme on mount
    onMount(() => {
        const params = getQueryParams(window.location.search);
        queryId = params.id || null;

        // Initial theme setup
        updateTheme();

        // Schedule theme update at midnight
        function scheduleNextUpdate() {
            const now = new Date();
            const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
            const timeUntilMidnight = tomorrow - now;

            setTimeout(() => {
                updateTheme();
                scheduleNextUpdate();
            }, timeUntilMidnight);
        }

        scheduleNextUpdate();
    });

    function handleNavClick(path) {
        navigate(path);
        showNavigation.set(false);
    }

    // Show navigation when returning to main page
    $: if (url === '/') {
        showNavigation.set(true);
    }
</script>

<Router {url}>
    {#if $showNavigation}
        <nav class="main-nav">
            <div class="nav-content">
                <Link to="/" class="nav-logo" on:click={() => handleNavClick('/')}>
                    Heavenya Events Promoted
                </Link>
                <div class="nav-links">
                    <Link to="/" class="nav-link" on:click={() => handleNavClick('/')}>
                        Home
                    </Link>
                    <Link to="/post" class="nav-link" on:click={() => handleNavClick('/post')}>
                        Post Event
                    </Link>
                    <Link to="/app-promotion" class="nav-link" on:click={() => handleNavClick('/app-promotion')}>
                        App Promotion
                    </Link>
                </div>
            </div>
        </nav>
    {/if}

    <main class="transition-colors duration-300">
        <!-- Add Post route first -->
        <Route path="/post">
            <Post />
        </Route>

        <!-- Handle path parameter format -->
        <Route path="/event/:id" let:params>
            <EventDetails id={params.id} />
        </Route>

        <Route path="/app/event/:id" let:params>
            <EventDetails id={params.id} />
        </Route>

        <!-- Handle query parameter format -->
        <Route path="/app/event">
            {#if queryId}
                <EventDetails id={queryId} />
            {:else}
                <p>No event ID provided in the query string.</p>
            {/if}
        </Route>

        <Route path="/event">
            {#if queryId}
                <EventDetails id={queryId} />
            {:else}
                <p>No event ID provided in the query string.</p>
            {/if}
        </Route>

        <Route path="/app-promotion">
            <AppPromotion />
        </Route>

        <!-- Default route -->
        <Route path="/">
            <Main />
        </Route>
    </main>
</Router>