// utils.js

// Utility to check if the URL is a valid YouTube link
export function isYouTubeUrl(url) {
    if (!url) return false;
    return url.includes('youtube.com') || url.includes('youtu.be');
}

// Utility to get the YouTube Embed URL for standard videos
export function getYouTubeEmbedUrl(url) {
    if (!url) return '';

    const videoId = url.match(/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/);
    if (videoId && videoId[1]) {
        // Return a cleaner embed URL with privacy-enhanced mode
        return `https://www.youtube-nocookie.com/embed/${videoId[1]}?rel=0&autohide=1&modestbranding=1`;
    }
    return '';
}

// Utility to get the YouTube Shorts Embed URL
export function getYouTubeShortsEmbedUrl(url) {
    if (!url) return '';

    const videoId = url.match(/youtube\.com\/shorts\/([^"&?\/\s]{11})/);
    if (videoId && videoId[1]) {
        // Return a cleaner embed URL with privacy-enhanced mode
        return `https://www.youtube-nocookie.com/embed/${videoId[1]}?rel=0&autohide=1&modestbranding=1`;
    }
    return '';
}

// Lazy load the YouTube iframe when needed
export function lazyLoadYouTubeEmbed(videoId, containerId) {
    const container = document.getElementById(containerId);
    if (!container) return;

    // Create the iframe dynamically
    const iframe = document.createElement('iframe');
    iframe.src = `https://www.youtube-nocookie.com/embed/${videoId}?rel=0&autohide=1&modestbranding=1`;
    iframe.frameBorder = "0";
    iframe.allow = "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture";
    iframe.allowFullscreen = true;

    // Append the iframe to the container
    container.appendChild(iframe);

    // Detect if iframe was blocked by a content blocker
    detectIframeBlock(iframe);
}

// Function to detect if an iframe was blocked by a content blocker
function detectIframeBlock(iframe) {
    iframe.onload = () => {
        console.log("Iframe loaded successfully");
    };
    iframe.onerror = () => {
        console.log("Iframe blocked by content blocker");
        alert("The video could not be loaded. You may want to check your content blocker settings.");
    };
}

// Provide a direct YouTube link as a fallback if the embed is blocked
export function getFallbackLink(url) {
    if (url) {
        return url.includes('youtube.com') ? url : `https://www.youtube.com/watch?v=${url.split('/')[3]}`;
    }
    return '';
}
