<!-- Main.svelte -->
<script>
  import { onMount } from 'svelte';
  import { Link } from 'svelte-routing';
  import { db, collection, query, orderBy, getDocs } from './firebase';
  import { isYouTubeUrl, getYouTubeEmbedUrl, getYouTubeShortsEmbedUrl } from './utils';
  import { format } from 'date-fns'; // Import date-fns function

  let events = [];
  let filteredEvents = [];
  let isLoading = true;
  let searchTerm = '';

  // Fetch events from the database
  onMount(async () => {
    try {
      const eventsRef = collection(db, 'events');
      const q = query(eventsRef, orderBy('eventDate', 'desc'));
      const querySnapshot = await getDocs(q);
      events = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        eventDate: doc.data().eventDate.toDate(),
        eventDateLocal: doc.data().eventDateLocal ? new Date(doc.data().eventDateLocal) : null,
        eventDateUTC: doc.data().eventDateUTC,
        url: doc.data().url, // Fetching URL from Firestore
        learnMoreUrl: doc.data().learnMoreUrl // Fetching learnMoreUrl from Firestore
      }));
      filteredEvents = events;  // Initialize filteredEvents with all events
    } catch (error) {
      console.error('Error fetching events:', error);
    } finally {
      isLoading = false;
    }
  });

  // Reactive filtering based on search term
  $: {
    filteredEvents = events.filter(event => 
      event.eventName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.locationAddress.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }
</script>

<main class="min-h-screen bg-gray-100 p-4">
  <div class="max-w-4xl mx-auto">
    <!-- Search Bar -->
    <div class="search-bar-container mb-6">
      <div class="search-bar">
        <input
          type="text"
          bind:value={searchTerm}
          placeholder="Search events..."
          class="search-input"
        />
      </div>
    </div>

    <!-- Loading Spinner -->
    {#if isLoading}
      <div class="flex justify-center items-center h-64">
        <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    {:else}
      <!-- No Events Found Message -->
      {#if filteredEvents.length === 0}
        <div class="text-center text-gray-500">No events found.</div>
      {:else}
        <!-- Event List -->
        <div class="grid gap-4 md:grid-cols-2">
          {#each filteredEvents as event}
            <div class="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow">
              <!-- YouTube Video or Image Content -->
              {#if event.url && isYouTubeUrl(event.url)}
                <div class="media-wrapper">
                  <div class="video-container">
                    {#if event.url.includes("youtube.com/shorts")}
                      <iframe 
                        title="YouTube Shorts Video"
                        width="100%" 
                        height="100%" 
                        src={getYouTubeShortsEmbedUrl(event.url)} 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen
                        onerror="this.style.display='none'; document.getElementById('video-error-{event.id}').style.display='block';">
                      </iframe>
                    {:else}
                      <iframe 
                        title="YouTube Video"
                        width="100%" 
                        height="100%" 
                        src={getYouTubeEmbedUrl(event.url)} 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen
                        onerror="this.style.display='none'; document.getElementById('video-error-{event.id}').style.display='block';">
                      </iframe>
                    {/if}
                  </div>
                </div>
                <!-- Fallback Message -->
                <div id="video-error-{event.id}" style="display: none; text-align: center;">
                  <p>Video cannot be displayed due to content blockers. Please disable your content blocker or <a href={event.url} target="_blank">view it on YouTube</a>.</p>
                </div>
              {:else if event.url}
                <div class="media-wrapper">
                    <img 
                        src={event.url} 
                        alt={event.eventName}
                        class="event-image"
                        on:error={(e) => {
                            e.target.style.display = 'none';
                            console.error('Image failed to load:', event.url);
                        }}
                    />
                </div>
              {/if}

              <!-- Event Name with Link to Event Details -->
              <Link to={`/event/${event.id}`}>
                <h2 class="text-xl font-semibold mb-2">{event.eventName}</h2>
              </Link>

              <p class="text-gray-600">
                {format(event.eventDate, "EEEE MMMM do yyyy, h:mma")}
              </p>
              <p class="text-gray-600">{event.locationAddress}</p>

              {#if event.imageUrl}
                <img src={event.imageUrl} alt={event.eventName} class="w-full h-auto mt-4" />
              {/if}

              <!-- Learn More Button that opens the Firestore link -->
              {#if event.learnMoreUrl}
                <div class="mt-4">
                  <a href={event.learnMoreUrl} target="_blank" rel="noopener noreferrer" class="learn-more-button">
                    Learn More
                  </a>
                </div>
              {/if}
            </div>
          {/each}
        </div>
      {/if}
    {/if}
  </div>
</main>

