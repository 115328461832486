<!-- EventDetails.svelte -->
<script>
  import { onMount } from 'svelte';
  import { db, doc, getDoc } from './firebase';
  import { isYouTubeUrl, getYouTubeEmbedUrl, getYouTubeShortsEmbedUrl } from './utils';
  import { format } from 'date-fns'; // Import date-fns function
  import { showNavigation } from './stores/navigation';

  export let id; // Get id as a prop instead of from $page store

  let event = null;
  let isLoading = true;
  let isMobileOrTablet = false;

  // Function to check if device is mobile or tablet
  function checkScreenSize() {
    isMobileOrTablet = window.innerWidth <= 1024;
  }

  onMount(async () => {
    // Hide navigation when mounting EventDetails
    showNavigation.set(false);
    try {
      if (id) {
        const eventRef = doc(db, 'events', id);
        const docSnap = await getDoc(eventRef);
        if (docSnap.exists()) {
          event = {
            id: docSnap.id,
            ...docSnap.data(),
            eventDate: docSnap.data().eventDate.toDate(),
            eventDateLocal: docSnap.data().eventDateLocal ? new Date(docSnap.data().eventDateLocal) : null,
            eventDateUTC: docSnap.data().eventDateUTC,
          };
        } else {
          console.log("No such event!");
        }
      }
    } catch (error) {
      console.error('Error fetching event:', error);
    } finally {
      isLoading = false;
    }

    // Initial check for screen size
    checkScreenSize();

    // Add event listener for resize
    window.addEventListener('resize', checkScreenSize);

    // Cleanup
    return () => {
      // Show navigation when unmounting EventDetails
      showNavigation.set(true);
      window.removeEventListener('resize', checkScreenSize);
    };
  });
</script>

<main class="min-h-screen bg-gray-100 p-4">
  <div class="max-w-4xl mx-auto">
    {#if isLoading}
      <div class="flex justify-center items-center h-64">
        <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    {:else if !event}
      <div class="text-center py-12">
        <p class="text-gray-600 text-lg">Event not found</p>
      </div>
    {:else}
      <div class="bg-white rounded-lg shadow-md p-6">
        <h2 class="text-2xl font-semibold mb-4">{event.eventName}</h2>
        <div class="date-times">
          <p><strong>Date:</strong> {format(event.eventDate, "EEEE MMMM do yyyy, h:mma")}</p>
        </div>
        <p><strong>Location:</strong> {event.locationAddress}</p>

        <!-- Media and Button Container -->
        <div class="media-button-container">
          <div class="media-wrapper">
            {#if event.url && isYouTubeUrl(event.url)}
              {#if event.url.includes("youtube.com/shorts")}
                <iframe 
                  title="YouTube Shorts Video"
                  src={getYouTubeShortsEmbedUrl(event.url)} 
                  frameborder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                  allowfullscreen
                ></iframe>
              {:else}
                <iframe 
                  title="YouTube Video"
                  src={getYouTubeEmbedUrl(event.url)} 
                  frameborder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                  allowfullscreen
                ></iframe>
              {/if}
            {:else if event.url}
              <img 
                src={event.url} 
                alt={event.eventName}
                class="event-image"
              />
            {/if}
          </div>
          
          {#if event.learnMoreUrl}
            <a 
              href={event.learnMoreUrl} 
              target="_blank" 
              rel="noopener noreferrer" 
              class="learn-more-btn"
            >
              Learn More
            </a>
          {/if}
        </div>

        {#if event.imageUrl}
          <img src={event.imageUrl} alt={event.eventName} class="w-full h-auto mt-4" />
        {/if}

        <!-- Event Details Section -->
        {#if event.details}
          <div class="mt-8 prose max-w-none">
            <h2 class="text-xl font-semibold mb-4"> </h2>
            <div class="text-gray-700 whitespace-pre-line">
              {event.details}
            </div>
          </div>
        {/if}
      </div>
    {/if}
  </div>

  {#if isMobileOrTablet}
    <div class="mobile-app-bar">
      <div class="mobile-app-bar-content">
        <a 
          href="https://apps.apple.com/us/app/heavenya-christian-fellowship/id1498340882"
          target="_blank"
          rel="noopener noreferrer"
          class="app-store-link"
        >
          <img 
            src="/assets/apple_store.png" 
            alt="Download on App Store" 
            class="app-store-image"
          />
        </a>
        <a 
          href="https://play.google.com/store/apps/details?id=com.faithful.heavenya"
          target="_blank"
          rel="noopener noreferrer"
          class="app-store-link"
        >
          <img 
            src="/assets/google_store.png" 
            alt="Get it on Google Play" 
            class="app-store-image"
          />
        </a>
      </div>
    </div>
  {/if}
</main>

